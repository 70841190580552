<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <b-form-group
                  :key="field.label"
                  :label="field.label"
                  v-for="field in fields"
                >
                  <b-form-input
                    v-model="form[field.key]"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar</b-button
                >
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar</b-button
                >
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../../core/services/api.service";

export default {
  name: "Create",
  props: ["fields", "backend_url", "modelName", "list_url"],
  data() {
    return {
      action: "Crear",
      form: {},
      isEdit: false,
    };
  },
  mounted() {
    console.log(this.$router.resolve({ name: this.list_url }));
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.modelName,
        route: `${
          this.$router.resolve({ name: this.list_url }).route.fullPath
        }`,
      },
      { title: `${this.action} ${this.modelName}` },
    ]);
  },
  methods: {
    getItem() {
      if (this.$route.params.id === undefined) {
        return false;
      }
      this.action = "Editar";
      this.isEdit = true;
      ApiService.get(`${this.backend_url}/${this.$route.params.id}`).then(
        (res) => {
          this.form = res.data;
        }
      );
    },
    resetForm() {
      this.form = {};
    },
    submit() {
      let method = this.isEdit === true ? ApiService.put : ApiService.post;
      let url =
        this.isEdit === true
          ? `${this.backend_url}/${this.form.id}/`
          : `${this.backend_url}/`;

      method(url, this.form)
        .then(() => {
          this.$successToast();
          if (this.isEdit === false) {
            this.form = {};
          }
        })
        .catch((err) => {
          console.log(err);
          this.$errorToast(err);
        });
    },
  },
};
</script>
