<template>
  <div>
    <Create
      :fields="fields"
      model="brand"
      modelName="Marca"
      list_url="brand_list"
      backend_url="core/brand"
    ></Create>
  </div>
</template>

<script>
import Create from "@/view/pages/crud/Create";

export default {
  name: "BrandCreate",
  components: { Create },
  data() {
    return {
      fields: [{ key: "name", label: "Nombre" }],
    };
  },
};
</script>

<style scoped></style>
